<template>
	<div class="commonHeight">
		<div class="chargeParking flex">
			<div class="park" :class="active==index?'active':''" @click="change(index,item.id)"
					 v-for="(item,index) in chargeParking "
					 :key="index">
				{{ item.parkingName }}
			</div>
		</div>
		<div class="import flex">
			<div class="model flex" @click="upload(0)" style="cursor: pointer;">
				<img src="../../assets/img/acc_a.png" alt="">
				<h2>会员信息导入</h2>
			</div>
			<div class="model flex" @click="upload(1)" style="cursor: pointer;">
				<img src="../../assets/img/acc_b.png" alt="">
				<h2>在场车辆信息导入</h2>
			</div>
			<div class="model flex" @click="upload(2)" style="cursor: pointer;">
				<img src="../../assets/img/acc_c.png" alt="">
				<h2>岗亭收入信息导入</h2>
			</div>
			<!--            <div style="display: none">
											<el-upload
															class="model flex"
															action="string"
															name="uploadFile"
															ref="upload"
															:limit="1"
															accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
															:on-success="successUpload"
															:show-file-list="false"
															:before-upload="beforeAvatarUpload"
															:auto-upload="false"
															:http-request="handleChange"
											>
													<img src="../../assets/img/acc_a.png" alt="">
													<h2>会员信息导入</h2>
											</el-upload>
											<el-upload
															class="model flex"
															action="string"
															name="uploadFile"
															ref="upload"
															:limit="1"
															accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
															:on-success="successUpload"
															:show-file-list="false"
															:before-upload="beforeAvatarUpload"
															:http-request="handleCarInfoChange">
													<img src="../../assets/img/acc_b.png" alt="">
													<h2>在场车辆信息导入</h2>
											</el-upload>
											<el-upload
															class="model flex"
															action="string"
															name="uploadFile"
															ref="upload"
															:limit="1"
															accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
															:on-success="successUpload"
															:show-file-list="false"
															:before-upload="beforeAvatarUpload"
															:auto-upload="false"
															:http-request="handleIncomeInfoChange">
													<img src="../../assets/img/acc_c.png" alt="">
													<h2>岗亭收入信息导入</h2>
											</el-upload>
									</div>-->

		</div>
		<el-dialog
				top="0" center class="commonDia updateMem" :close-on-click-modal="false"
				:visible.sync="carStaticsImport" :show-close="false"
				width="450px" :close-on-press-escape="false">
			<template #title>
				<h1 class="tit">导入</h1>
			</template>
			<el-form label-position="top" ref="form" class="rowsInfo" label-suffix="：" label-width="80px">
				<el-form-item label="办理人">
					<el-input v-model="handler" placeholder="请输入办理人"></el-input>
				</el-form-item>
				<el-form-item label="导入文件">
					<el-upload multiple
										 ref="upload"
										 class="upload-demo"
										 action="string"
										 name="uploadFile"
										 :limit="1"
										 :file-list="files"
										 :before-upload="beforeAvatarUpload"
										 :auto-upload="false"
										 :on-success="successUpload"
										 :http-request="handleChange"
										 accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel">
						<!--:headers="{token:headToken}"-->
						<el-button class="uploadImg" type="primary"><i class="el-icon-plus avatar-uploader-icon"></i>文件上传
						</el-button>
					</el-upload>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="carStaticsImport=false">取 消</el-button>
				<el-button type="primary" @click="importFileBtn">确 定</el-button>
			</div>
		</el-dialog>
	</div>

</template>

<script>
import { getCarParkInfoList } from "@/api/api";

export default {
	name: "account",
	data() {
		return {
			active: 0,
			chargeParking: ["银星科技大厦", "智界一期", "智界二期", "智界三期", "产业基地"],
			parkId: 0,
			uploadUrl: "/car/member-info/import",
			uploadCarInfoUrl: this.$local.baseURL() + "/car/presentCarInfo/import",
			uploadIncomeInfoUrl: this.$local.baseURL() + "/car/sentry-income-info/import",
			headToken: localStorage.accessToken,
			handler: "",
			carStaticsImport: false,
			disabled: false,
			files: []
		};
	},
	mounted() {
		this.getParkingInfo();
	},
	methods: {
		change(index, id) {
			this.active = index;
			this.parkId = id;
		},
		//获取停车场
		getParkingInfo() {
			let params = {
				page: 1,
				size: 9999
			};
			getCarParkInfoList(JSON.stringify(params)).then(res => {
				this.chargeParking = res.data.rows;
				this.parkId = this.chargeParking[0].id;
			});
		},
		upload(type) {
			this.carStaticsImport = true;
			if (type == 0) {
				this.uploadUrl = "/car/member-info/import";
			} else if (type == 1) {
				this.uploadUrl = "/car/presentCarInfo/import";
			} else {
				this.uploadUrl = "/car/sentry-income-info/import";
			}
		},
		importFileBtn() {
			this.disabled = true;
			this.$refs.upload.submit();
		},
		beforeAvatarUpload(file) {
			const isExcel = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || "application/vnd.ms-excel";
			const isChargPark = this.parkId !== "";
			if (!isExcel) {
				this.$message.error("上传文件只能是xlsx格式");
				return;
			}
			if (!isChargPark) {
				this.$message.error("请先选择停车场");
			}
			return isExcel && isChargPark;
		},
		successUpload(res) {
			if (res.code == 200) {
				this.parkId = "";
				this.files = [];
				this.handler = "";
				this.carStaticsImport = false;
				this.$message.success("导入成功");
				this.disabled = false;
			}
		},
		handleChange(param) {
			const loading = this.$loading({
				lock: true,
				text: "上传中，请稍后.....",
				spinner: "el-icon-loading",
				background: "rgba(0, 0, 0, 0.7)"
			});
			let formData = new FormData();
			formData.append("uploadFile", param.file);
			formData.append("parkId", this.parkId);
			formData.append("handler", this.handler);

			this.$postJson(this.uploadUrl, formData).then(res => {
				if (res.data.code == 200) {
					this.parkId = "";
					this.handler = "";
					loading.close();
					this.carStaticsImport = false;
					this.$message.success("文件正在上传中，请稍后查看");
					this.disabled = false;
				} else {
					loading.close();
					this.$message.error(res.data.msg);
				}
			});
		},
		cancel() {
			this.carStaticsImport = false;
			this.parkId = "";
			this.files = [];
			this.handler = "";
		}
	}
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/account";
@import "../../assets/css/table";

.uploadImg {
	width: 138px;
	height: 42px;
	background: #F0F2F8;
	border: none;
	color: #999DAF;

	i {
		margin-right: 5px;
	}
}
</style>
